<template>
  <div
    :class="{
      daypicker: true,
      daypicker_valid: this.status === 'valid',
      daypicker_invalid: this.status === 'invalid',
    }"
    :data-paint="paint"
    v-click-outside="hideList"
  >
    <div class="daypicker__field" v-on:click="toggleList">
      {{ day }}
    </div>
    <ul class="daypicker__list" v-show="listVisible">
      <perfect-scrollbar>
        <li
          class="daypicker__item"
          v-for="item in items"
          :key="item.id"
          :data-id="item.id"
          :data-num="item.num"
          v-on:click="selectItem"
        >
          {{ item.title }}
        </li>
      </perfect-scrollbar>
    </ul>
  </div>
</template>

<script>
  export default {
    name: "Daypicker",
    props: {
      type: String,
      paint: String,
      status: String,
      day: String,
    },
    data() {
      return {
        listVisible: false,
        className: {
          common: "daypicker__icon",
          active: "daypicker__icon daypicker__icon_rotate",
        },
        items: [
          {
            id: 1,
            num: 1,
            title: "ПН",
          },
          {
            id: 2,
            num: 2,
            title: "ВТ",
          },
          {
            id: 3,
            num: 3,
            title: "СР",
          },
          {
            id: 4,
            num: 4,
            title: "ЧТ",
          },
          {
            id: 5,
            num: 5,
            title: "ПТ",
          },
          {
            id: 6,
            num: 6,
            title: "СБ",
          },
          {
            id: 0,
            num: 7,
            title: "ВС",
          },
        ],
      };
    },
    methods: {
      toggleList() {
        this.listVisible = !this.listVisible;
      },
      hideList() {
        this.listVisible = false;
      },
      selectItem() {
        this.listVisible = false;
      },
    },
  };
</script>

<style lang="scss">
  .daypicker {
    width: 60px;
    min-width: 60px;
    position: relative;
    border: 1px solid transparent;
    border-radius: 6px;
    box-shadow: $shadowLight;
    background-color: $colorMainLight;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_valid {
      border-color: #41c6eb;
    }

    &_invalid {
      border-color: #ff8068;
    }

    &[data-paint="blue"] {
      .daypicker__field-text {
        color: #41c6eb;
        font-weight: 500;
      }
    }

    &[data-paint="orange"] {
      .daypicker__field-text {
        color: #f5b142;
        font-weight: 500;
      }
    }

    &__field {
      width: 100%;
      cursor: pointer;
      z-index: $zIndexDaypicker - 10;
      font-size: 12px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 6px 5px;
    }

    &__list {
      position: absolute;
      top: 23px;
      left: -1px;
      margin: 0;
      padding: 0 5px 5px;
      list-style: none;
      width: calc(100% + 2px);
      background-color: $colorMainLight;
      z-index: $zIndexDaypicker + 10;
      box-shadow: 4px 4px 4px #dcdcdc, -4px 4px 4px #f7f7f9eb;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      padding-top: 10px;

      &_invisible {
        display: none;
      }
    }

    .ps {
      height: 100px;
      padding: 0 5px;
    }

    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border-bottom: 1px solid #d8d8d8;
      padding: 8px 0;
      font-size: 12px;
      color: #2b1a0a;
      text-transform: uppercase;

      &:hover {
        color: #41c6eb;
      }

      &:first-of-type {
        border-top: 1px solid #d8d8d8;
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }

    &__wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .daypicker__title {
        font-weight: 500;
      }
    }
  }
</style>
