<template>
  <main class="system" :data-id="about.id">
    <div class="system__content">
      <Notification />
      <slot v-if="this.$route.params.id">
        <div class="system__header">
          <div class="system__navbar">
            <ul class="system__navbar-list">
              <li class="system__navbar-back" @click="goBack">
                <span class="system__navbar-arrow">
                  <font-awesome-icon icon="arrow-left" />
                </span>
                <div class="system__navbar-info">
                  <span class="system__navbar-name">{{ navbar.back }}</span>
                  <span class="system__navbar-feature" v-if="false"></span>
                </div>
              </li>
              <li
                v-for="item in navbar.list"
                :class="[
                  item.isActive
                    ? 'system__navbar-item system__navbar-item_current'
                    : 'system__navbar-item',
                ]"
                :data-id="item.id"
                :key="item.id"
                @click="setActiveItem"
              >
                <span class="system__navbar-quantity">{{ item.quantity }}</span>
                <div class="system__navbar-info">
                  <span class="system__navbar-name">{{ item.name }}</span>
                  <span class="system__navbar-feature">{{ item.feature }}</span>
                </div>
              </li>
            </ul>
          </div>
          <div
            class="system__wrapper"
            v-if="about.type === 'pro' && navbar.current === 2"
          >
            <div class="system__search">
              <input
                class="system__search-input"
                type="text"
                :value="navbar.search.query"
                placeholder="Поиск"
                @input="changeSearchQuery"
                @keypress="searchObjects"
              />
              <span
                class="system__search-clear"
                v-if="
                  navbar.search.query !== null && navbar.search.query.length > 2
                "
                @click="clearSearch"
                >&times;</span
              >
              <button
                class="system__search-button"
                :disabled="!navbar.search.validation"
                @click="searchObjects"
              >
                <font-awesome-icon icon="fa-magnifying-glass" />
              </button>
            </div>
            <Button
              :type="'text'"
              :value="'Создать объект'"
              :paint="'blue'"
              @click="createObject"
            />
          </div>
        </div>
        <SystemInfo v-if="navbar.current === 1" />
        <SystemTasks v-if="navbar.current === 2 && about.type !== 'pro'"  />
        <SystemChildren v-if="navbar.current === 2  && about.type === 'pro'" />
        <SystemSettings v-if="navbar.current === 3" />
      </slot>
    </div>
  </main>
</template>

<script>
  import { mapGetters, mapMutations, mapActions } from "vuex";

  import Notification from "../layout/Notification";

  import SystemInfo from "../pages/System-Info";
  import SystemTasks from "../pages/System-Tasks";
  import SystemChildren from "../pages/System-Children";
  import SystemSettings from "../pages/System-Settings";

  import Button from "../components/Button";

  export default {
    name: "System",
    components: {
      Notification,
      SystemInfo,
      SystemTasks,
      SystemChildren,
      SystemSettings,
      Button,
    },
    computed: {
      ...mapGetters({
        navbar: "getSystemNavbar",
        about: "getSystemAbout",
      }),
    },
    methods: {
      ...mapMutations([
        "setSystemActiveNavbarItem",
        "createModal",
      ]),
      ...mapActions([
        "fetchSystem",
      ]),
      goBack() {
        if (this.about.type === 'pro_home') {
          localStorage.setItem("system__navbar-current", '2');

          this.$router.push(`/system/${localStorage.getItem("system__previous-id")}`);
          this.fetchSystem(localStorage.getItem("system__previous-id"));
        } else {
          localStorage.removeItem("system__navbar-current");

          this.$router.push("/systems");
        }        
      },
      setActiveItem(event) {
        let item = event.target.closest(".system__navbar-item");

        this.setSystemActiveNavbarItem(item);
      },
      createObject() {
        let payload = {
          type: "object",
          page: 'system',
          params: {
            type: "pro_home",
            control_channels: null,
            pro_system_id: +document
              .querySelector(".system")
              .getAttribute("data-id"),
            name: null,
            date: null,
            contract: null,
          },
        };

        this.createModal(payload);
      },
      searchObjects(event) {
        console.log(11);
        if (event.type === "keypress") {
          if (this.navbar.search.validation && event.key === "Enter") {
            this.searchSystemsObjects(this.navbar.search.query);
          }
        } else {
          this.searchSystemsObjects(this.navbar.search.query);
        }
      },
    },
    created() {
      this.fetchSystem(this.$route.params.id);
    },
  };
</script>

<style lang="scss" scoped>
  .system {
    position: relative;
    width: 100%;

    @include mediaquery(sm) {
      width: calc(100% - 75px);
    }

    &__content {
      display: flex;
      flex-direction: column;
      padding-bottom: 50px;

      @include mediaquery(sm) {
        padding-bottom: 100px;
      }
    }

    &__navbar {
      width: 100%;
      margin-bottom: 20px;

      &-list {
        padding: 0;
        margin: 0;
        list-style: none;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        @include mediaquery(md) {
          flex-direction: row;
          margin-left: -5px;
          margin-right: -5px;
          margin-top: -5px;
          margin-bottom: -10px;
          width: calc(100% + 10px);
        }
      }

      &-back {
        display: flex;
        width: 100%;
        border-radius: 6px;
        border: 2px solid #41c6eb;
        background-color: #41c6eb;
        box-shadow: $shadowLight;
        padding: 10px;
        cursor: pointer;
        margin-bottom: 10px;

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        .system__navbar-arrow {
          color: #41c6eb;
          background-color: #fff;
        }

        .system__navbar-name {
          color: #fff;
        }

        .system__navbar-feature {
          color: #fff;
        }
      }

      &-item {
        @include base;

        display: flex;
        width: 100%;
        border: 2px solid $colorMainLight;
        padding: 10px;
        margin-bottom: 10px;
        cursor: pointer;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include mediaquery(md) {
          width: calc(50% - 10px);
          margin: 5px;

          &:last-of-type {
            margin-bottom: 5px;
          }
        }

        @include mediaquery(lg) {
          width: calc(33.333333% - 10px);
        }

        @include mediaquery(xl) {
          width: calc(25% - 10px);
        }

        &_current {
          border: 2px solid #41c6eb;

          .system__navbar-quantity {
            color: #41c6eb;
            background-color: #fff;
          }

          .system__navbar-name {
            color: #222325;
          }
        }
      }

      &-arrow,
      &-quantity {
        width: 40px;
        min-width: 40px;
        height: 40px;
        border-radius: 2px;
        background-color: #dddddd;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;
        overflow: hidden;
      }

      &-name {
        font-weight: 500;
        font-size: 14px;
        color: #9b9b9b;
        text-transform: uppercase;
      }

      &-feature {
        margin-top: 5px;
        font-size: 10px;
        color: #9b9b9b;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 15px;

      @include mediaquery(sm) {
        flex-direction: row;
      }
    }

    &__search {
      @include base;

      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 10px;

      @include mediaquery(sm) {
        margin-bottom: 0;
        width: 300px;
      }

      @include mediaquery(md) {
        width: calc(50% - 5px);
      }

      @include mediaquery(lg) {
        width: calc(33.333333% - 7px);
      }

      @include mediaquery(xl) {
        width: calc(25% - 7px);
      }

      &-input {
        height: 36px;
        width: 100%;
        padding-left: 15px;
        background: none;
        border: transparent;
        font-family: "Avenir";
        font-size: 12px;

        &::placeholder {
          color: #9b9b9b;
        }
      }

      &-button {
        border: 0;
        height: 20px;
        width: 36px;
        min-width: 36px;
        border-left: 1px solid #ddd;
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease-in;

        &:disabled {
          cursor: auto;
          pointer-events: none;
          color: #9b9b9b;
        }
      }

      &-clear {
        color: #000;
        font-size: 16px;
        margin: 0 10px;
        cursor: pointer;
      }
    }
  }
</style>